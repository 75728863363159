@import 'normalize.scss';

:root {
    --body-bg: white;

    --card-bg: #fff;
    --card-radius: 6px;

    --avatar-bg: #E5E7EB;


    --depth-4: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    --depth-8: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    --depth-16: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    --depth-64: 0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18);

    --rounded: 8px;
    --rounded-small: 4px;


    --ink: #111827;
    --gray: #4b5563;
    --sky: #d1d5db;
    --light: #f3f4f6;

    --red: #991b1b;
    --blue: #1e40af;
    --green: #166534;
    --orange: #9a3412;

    --border-color: #E5E7EB;

    --input-background: #fff;
    --input-border: #c9cccf;
    --input-border-focus: var(--blue);


    --btn-border: #9CA3AF;
    --btn-color: #F9FAFB;
    --btn-color-hover: #F3F4F6;
    --btn-color-focus: #E5E7EB;
    --btn-primary-color: var(--blue);
    --btn-primary-color-hover: #1E40AF;
    --btn-primary-color-active: #1E3A8A;

    --accent: var(--blue);
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    font-size: 62.5%;
}

body {
    color: var(--ink);
    font-family: system-ui, sans-serif;
    font-size: 1.6rem;
    line-height: 2.2rem;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    background: var(--body-bg);
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.text-title-1,
.text-display-1 {
    font-size: 4rem;
    line-height: 4.8rem;
}

.text-title-2,
.text-display-2 {
    font-size: 3.2rem;
    line-height: 4rem;
}

.text-title-3,
.text-display-3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
}

.text-xlarge {
    font-size: 2rem;
    line-height: 2.8rem;
}


.text-large {
    font-size: 1.8rem;
    line-height: 2.4rem;
}

.text-regular {
    font-size: 1.6rem;
    line-height: 2.2rem
}

.text-small {
    font-size: 1.4rem;
    line-height: 1.8rem
}

.text-micro {
    font-size: 1.2rem;
    line-height: 1.6rem
}

.text-normal {
    font-weight: 400;
}

.text-bold {
    font-weight: 700
}

.text-semibold {
    font-weight: 600
}

.text-extrabold {
    font-weight: 800
}

.text-uppercase {
    text-transform: uppercase;
}

.color-ink {
    color: var(--ink);
}

.color-gray {
    color: var(--gray);
}

.color-sky {
    color: var(--sky);
}

.color-light {
    color: var(--light);
}

.color-red {
    color: var(--red);
}

.color-green {
    color: var(--green);
}

.color-blue {
    color: var(--blue);
}

.color-orange {
    color: var(--orange);
}

.color-white {
    color: white;
}

.flex {
    display: flex;
    min-width: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.flex-center {
    align-items: center;
}

.flex-end {
    align-items: flex-end;
}

.flex-start {
    align-items: flex-start;
}

.btn {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: 24px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 16px;
    border: 0;
    text-align: center;
    color: inherit;
    text-decoration: none !important;
    white-space: nowrap;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;


    background: #fff;


    transition: .12s;

    border: solid 1px #babfc3;
    border-top-color: #c9cccf;
    border-bottom-color: #babfc4;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);

    img,
    svg {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 6px;
        margin-left: -4px;
    }


    &:hover,
    &:focus {
        background-color: var(--btn-color-hover);
    }

    &:active {
        background: #E5E7EB
    }



}

.btn-small {
    padding: 3px 12px;
    border-radius: var(--rounded-small);
    font-size: 14px;

    svg,
    img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        margin-left: -4px;
    }
}

.btn-large {
    padding: 11px 24px;
    font-size: 18px;

    img,
    svg {
        margin-right: 12px;
    }
}

.btn-primary {
    background: var(--btn-primary-color);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);

    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 0;

    &:hover,
    &:focus {
        background: var(--btn-primary-color-hover);
    }

    &:active {
        background: var(--btn-primary-color-active);
    }

    &.btn-small {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &.btn-large {
        padding-top: 12px;
        padding-bottom: 12px;
    }

}

.btn-primary .ripple {
    background-color: gold;
}

.btn-icon {
    padding: 7px;

    img,
    svg {
        margin: 0;
    }
}

.btn-icon.btn-small {
    padding: 6px;

    img,
    svg {
        width: 18px;
        height: 18px;
    }
}

.btn-danger {
    color: var(--red);
}

.btn-fullwidth {
    display: flex;
    width: 100%;
}

.btn:disabled {

    opacity: .64;
    cursor: not-allowed;

}

.btn-disabled {
    cursor: not-allowed;
    color: #4B5563;
    background-color: #F3F4F6 !important;
}




.btn-rounded {
    border-radius: 10em;
}

.btn-link {
    border-color: rgba(0, 0, 0, 0);
    color: var(--accent);
    box-shadow: none !important;
    background: rgba(0, 0, 0, 0);
    font-weight: 400;
}

.btn-link-semibold {
    font-weight: 600;
}


.btn-line {
    border-color: var(--btn-primary-color);
    color: var(--btn-primary-color);
    box-shadow: none !important;
    background: rgba(0, 0, 0, 0);
}

.btn-menu {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    width: 100%;
    text-align: left;
    justify-content: left;
    background: rgba(0, 0, 0, 0);

    .affix {
        margin: 0 -4px 0 0;
    }
}










label {
    display: block
}

label+.form-control {
    margin-top: 4px
}

.form-control {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    border: solid 1px var(--input-border);
    border-top-color: #aeb4b9;
    border-radius: 4px;
    outline: 0 !important;
    position: relative;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 8px;
    text-overflow: ellipsis;
    height: 40px;
    transition: border-color .12s ease-in-out, box-shadow .12s ease-in-out;
    background: var(--input-background);
    color: var(--ink);
}

.form-control:focus {
    border-color: var(--input-border-focus);
    //box-shadow: 0 0 0 0.16rem rgba(29, 78, 216, .24);
    position: relative;
    z-index: 2
}

.form-control:read-only:focus {
    border: solid 1px var(--input-border);
    box-shadow: none;
}



.input-group {
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: solid 1px var(--input-border);
    border-radius: 4px;
    transition: border-color .12s ease-in-out, box-shadow .12s ease-in-out;
    background: var(--input-background);


    .form-control {
        flex: 1;
        box-shadow: none;
        border: 0;
        min-height: 38px;
    }

    &:focus-within {
        border-color: var(--input-border-focus);
        //box-shadow: 0 0 0 0.16rem rgba(29, 78, 216, .24);
        position: relative;
        z-index: 2
    }
}

.form-control-invalid {
    border-color: var(--red) !important;
    //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABYlBMVEUAAAD/gID/VVX/gID/Zmb/gID/bW3/cXH/Zmb/dHT/eHj/cXHya2vyc3PzbW3zdHT1cHD1dnb2cXH2bW32cnL3b2/5bm76cXH6b2/6cXH2b2/2cnL2cHD2cnL5cnL5cHD5cXH5cnL5cXH5cHD3cHD3cnL3cHD3cXH3cHD3cnL4cXH4cnL4cXH4cnL4cXH4cXH5cHD5cnL5cXH5cnL5cXH5cnL5cXH3cHD3cXH3cHD3cXH4cXH4cnL4cXH4cnL4cXH4cXH4cHD4cXH4cHD4cXH4cXH4cnL4cXH3cXH3cXH3cHD3cXH4cXH4cXH4cXH4cXH4cXH4cHD4cXH4cXH4cXH4cXH4cXH4cXH4cHD4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cHD4cXH4cXH4cXH4cXH4cXH5cXH3cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH4cXH///8W0x/LAAAAdHRSTlMAAgMEBQYHCQoLERITFBUWGRobHB0eLDQ1Njc4OTpTVFZXWFlbXGJjZGVmZ2hpc3V2d3h5ent+hIWGh4iJkZKTlZaXmJmam5ylp6ipqqusra6xs7W3ztHS09TV2Nrd4OHi4+Tl5ufp6uvs7e7x8vP19/j5+gKSVZYAAAHjSURBVHja3dKFjuQwEATQDg0z4/EyMzMzMzOz/1/XHUmRk3G8uhPvE1ZpKuSBL0ktd63uP3y8P+wutxcU+Ex68IJxTvsSIBOd+mAOb2MhcFX1wARuf4KYPsNMj7O1uYiuR3J1c0/MNKqBgG+TkYNqL9fVHDKyTp2Dbv7+pVlz1C2v5sJek2nqDzNQoXhpPhU4/KF2KwICyW2GfoBN5J6uHwGhJN3jJgi8SXp+7nkY4p6K3mMEOKl3bJrBbQDddIL8mQ/S99TcB/4rzH1gUen/Uw3uA2ii/5VixRKdr0828D9jkbdiJ6ZZkA1gHot2K61gqpUP6rFYstIeppx8UMBix0p0amH5IIrFrZXoFAz5wEMn8c+D/3+kPfMjSxXppW2ftQ6kGmyftQPTHEgt4k/abPd78oGE/9l2VOo5xhrZS9dhPlHAMoD5UHcfGEeYe7kiSSfR4j5ox/gaB844NUW3QfkN4zDwgrdYXaVAKE6veBcCm98MbSdBILXD0HdwmKD2sggVSles4oGIvkH9a7cfbIyON+rXNKjgwwW6auIm/vojRta8IKDTU6Hn+YZC1DCihcaFZ2YaUkHs1x0TuP4GrkJj78zhdSgAMsn+M8Y57onBZ5RCx/LO/fv7/fZia1aBr+gvwcHo4wBu0ekAAAAASUVORK5CYII=);


    background-position: calc(100% - 8px) center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    padding-right: 40px;

    &:focus {
        //box-shadow: 0 0 0 0.16rem rgba(185, 18, 28, .24);
    }
}

.form-control-valid {
    border-color: var(--green) !important;
    //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABL1BMVEUAAAAA/4BVqqpAv4AzzJkr1aok25I5xo4zzJku0aIr1ZUt0pY51Zw215Qz2Zkxzp4u0Zcx2J050Jc30Zs105Uz1Zky0ps11Jo01Zcz1Zky0Zs20pg105o01Jcz1Zk01Zg105o105gz0pkz1Jk11Jo10pk00pkz05gz0pgz0pk105o005kz1Jgz0pk005k005k11Jk005k005kz05g11Jk11Jo005o01Jkz05g105k005k01Jk00po005kz05g11Jk00po005k005kz05g005o005k005k005k00pkz05k005k005k005k005k005k005kz05k005k005k005k105k005k005k005k005k105k005k005k005k005k005k005k005k005k005k005n///94Q/UPAAAAY3RSTlMAAgMEBQYHCQoLDBESExQVFhobHB0eMzU2Nzg5Ojs8VFZXWmRlZmdod3h5enx9f4CChIWGh4iSk5WWmJmmp6mqq62ys7XR0tPU1dvd3uHi4+Tm5+jp6uvs7e7v8PLz9Pf4+fqkHJ2+AAACFUlEQVR42t2U5Z4rIQzFs+7u7u7uLnV3F97/HW7C/C6LzfR7z25bOPkHB2hLdS4dfQQKzWbB/3G42NESn76JM0mx6ylPfPSxwTQ1Hkbc+c08syi34YJ3PzlA+Xl7bqy7e2x++6XsOA9dNr7/mwcTe/2StxXk5lefpX3O108GNHu/zjPMPvh4kktgaDlFkXtjvuT6psGiGR/F1lVzJIteinhbBvWRG1W8R7RqS+CiFZrHnexM0X6dWOGFY/w6pPWYlNwbWs8BK19i5wA9IQSupPNG52fPhWfsDGAHf6Idwl6i/e134ylhoIK/88I/wtqzB496xcKBCHxgbduTh10svYuIH2tznjwsYPFXhOhUj3ryMI7lrIjRLvTw0v6cnYde2gkz4YyVFhxrtkD8KWgJ2pDO8Lc4b7ZvDMnvLDJPYKV5Cw+LNGltWUXGgsaby0pn6wX+Mkwe3tDZl/ujoyEyTH6wqmxVRwyrWyBlaDw/fBHpGbzGerBbytD43jB6l9oFEkM8M3h+OmsTsvNA+7L8P0PnV6m9W8UaztAjMOP1CGhP7AZ/ZmasvJ9ia7r9QG5qxeRX08wYEKnri/z6YY9q9xw1yP+0PMd9PIOFdgak/doJM873gUVd94yr8rq7MN7TM76w91Z1nNsusGs9wyxKr4Grhu/qOl67HQIvTVxGZTxyMQGt1DF/8P6bbzTyP+/7cx3QjvoHVSPceNCVe9EAAAAASUVORK5CYII=);



    background-position: calc(100% - 8px) center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    padding-right: 40px;

    &:focus {
        //box-shadow: 0 0 0 0.16rem rgba(4, 180, 27, .24);
    }
}

.form-control.disabled,
.form-control:disabled {
    cursor: not-allowed;
    color: gray !important;
}

.form-control-small {
    padding: 3px 8px;
    height: 32px;
    font-size: 14px;
}

input.form-control[type=search] {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAwFBMVEUAAACZmZmiormVqqqkpLafn6+WpbScqqqbpK2fpaydo7GZpqyboq6aoLKbpa+dorGapK2ao7CcpK2doq6bo7CcpbGeoq6dpbGdoq+co7CdpK6bo6+do66coq+co7CbpK6bo6+cpK6do6+bo6+doq+co6+dpLCco6+co7Cco6+co6+bpK+co6+do6+coq6co6+bo6+co6+do6+dorCco6+co6+co6+co6+co6+co6+co6+co6+co6+co6+co6////+8CE/kAAAAPnRSTlMABQsMDhAREhwlJygpKzM0NTo7PD0+P0FjZGVmdXZ3e31+f4CMk5SWt7m6u7y9vr/AwsXX2Nna2/Lz9Pn9/oLDY7MAAAE/SURBVHja7JQFlgMhEESJu7u7uzv3P9Z2xcObZsOz1R+l6I8lM8Kcf/zl4WK/XwzL/rfKIyN5Zx7/tNzWOcknTm2bvt4zkQpjt3b8S30v57fbA7n+xdDN0UHFMnFrJldot/j6KNY/cT0C9xT7CLPCmLoXqH8YmGPA1QclkXjNUsj8jFDGftVwQGGREYbUl1XDPIV9RlhZzR7AuTHCjvocauigcMcIWyvBSeHWdEkbRsCB5NSwoNl0yapvSGGJEfySSL1mGWQhwYBLZ+V+Tjwbirr8xYY/3/TJ8MwoOMYES1sS6/R9PRu0m7oLaCzBoBB0OIKFoQQHr9DghvHCgV4N3Ryt15tA04uPutAQHj7qu7RfqTeAr9hf7PabfimEVl2CmnifMj8HZxjPUfrGRtXAKJoKMAzqQaUi/jwfoy0AAMnDUpssdLNXAAAAAElFTkSuQmCC);
    background-position: 8px center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    padding-left: 40px;
    padding-right: 32px;
    border-radius: 100px;
}

input.form-control-small[type=search] {
    background-size: 18px 18px;
    padding-left: 32px;
}

select.form-control {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAALVBMVEUAAACeo62bpa+dorGeorCbpK2co7Cdo6+bo6+co66coq+co6+co6+co6////8OHxBcAAAADXRSTlMAMjM0Nzh3f4CI7e7wT3gqJQAAAGRJREFUeNrtyzEWgiEQA+H4o8uq5P7XlcezMUWgsNyvnkH5m1KiQbSAkRx3/Li9+HQ95yE9zRGUY/XsOD18r4fv5XhguqTfHddbe39I7+Q6BqfEkeBXx6EwvTmk90L6vUzslFI+7ckGE1NaKQIAAAAASUVORK5CYII=);
    background-position: 97.5% center;
    background-position: calc(100% - 4px) center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    padding-right: 32px;

    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #babfc3;
    border-top-color: #c9cccf;
    border-bottom-color: #babfc4;
}

select.form-control[multiple] {
    background-image: none;
    height: auto;
    max-height: 120px;
    padding-right: 8px
}

textarea.form-control {
    height: auto
}

.input-feedback {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
    color: var(--gray);
}

.input-feedback-invalid {
    color: var(--red)
}

.input-feedback-valid {
    color: var(--green)
}

.help-block.error {
    color: var(--vermilion)
}

.radio {
    display: flex;
    align-items: center;
    line-height: 24px;
}

.radio-btn {
    position: relative;
    display: block
}

.radio-btn input[type=radio] {
    opacity: 0;
    position: absolute
}

.radio-btn div,
.radio-btn span {
    display: block;
    width: 24px;
    height: 24px;
    border: solid 2px var(--sky);
    padding: 2px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    overflow: hidden
}

.radio-btn div:after,
.radio-btn span:after  {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.radio-btn input[type=radio]:checked~div,
.radio-btn input[type=radio]:checked~span {
    border-color: var(--accent);
    transition: 0.2s
}

.radio-btn input[type=radio]:checked~div:after,
.radio-btn input[type=radio]:checked~span:after {
    background: var(--accent);
    transition: 0.2s
}



.checkbox-btn {
    position: relative;
    display: block;
}

.checkbox-btn input[type=checkbox] {
    opacity: 0;
    position: absolute
}

.checkbox-btn div,
.checkbox-btn span {
    display: block;
    width: 24px;
    height: 24px;
    padding: 2px;
    background-color: white;
    border: solid 2px var(--sky);
    position: relative;
    overflow: hidden;
    border-radius: 4px
}

.checkbox-btn div:after,
.checkbox-btn span:after  {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: var(--sky);

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAALVBMVEUAAAD///////////////////////////////////////////////////////+hSKubAAAADnRSTlMAChUuM3V3f4CIwNHr7sq7CnQAAABsSURBVHja7NI1GoAwFAPgujf3vy0y4ZKPCWjmP9UnWloeRVvSF0TSA5H08N/wzatkOJ9RDeFlBlD02gdxGIch1RD3nRr3/NS466fGTT81iP8KoyzT+9zcg5oHR/ipwfihMXoqIfQDEqNgFAAACEUJ7ipu+uwAAAAASUVORK5CYII=);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;

}

.checkbox-btn input[type=checkbox]:checked~div,
.checkbox-btn input[type=checkbox]:checked~span {
    border-color: var(--accent);
    background: var(--accent);
    transition: 0.2s
}

.checkbox-btn input[type=checkbox]:checked~div:after,
.checkbox-btn input[type=checkbox]:checked~span:after  {
    background-color: var(--accent);
    transition: 0.2s;
    opacity: 1;
}


.switch {
    position: relative;
    display: block;
}

.switch__input {
    position: relative;
    display: block;
    height: 20px;
    width: 40px;
    background: var(--sky);
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 2px 0;
    box-shadow: 0 1px rgba(0, 0, 0, 0.08) inset
}

.switch__btn {
    position: absolute;
    left: 0;
    top: -2px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    border: solid 1px var(--sky);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.64), 0 3px 3px rgba(0, 0, 0, 0.08);
    content: '';
    transition: all 0.3s ease
}

.switch input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0
}

.switch input[type=checkbox]:checked~.switch__input {
    background: var(--accent);
}

.switch input[type=checkbox]:checked~.switch__input .switch__btn {
    left: 16px
}




.checkmark {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 24px;
    padding: 8px 0;
}


.checkmark-btn {
    position: relative;
    display: block;

}

.checkmark-btn div,
.checkmark-btn span {
    position: relative;
    display: block;
    height: 24px;
    width: 24px;
    background: var(--sky);
    border-radius: 50%;
    transition: 0.2s;
    overflow: hidden;
    transition: 0.2s;    
}

.checkmark-btn div:after,
.checkmark-btn span:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    background-color: var(--input-border);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAALVBMVEUAAAD///////////////////////////////////////////////////////+hSKubAAAADnRSTlMAChUuM3V3f4CIwNHr7sq7CnQAAABsSURBVHja7NI1GoAwFAPgujf3vy0y4ZKPCWjmP9UnWloeRVvSF0TSA5H08N/wzatkOJ9RDeFlBlD02gdxGIch1RD3nRr3/NS466fGTT81iP8KoyzT+9zcg5oHR/ipwfihMXoqIfQDEqNgFAAACEUJ7ipu+uwAAAAASUVORK5CYII=);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    background-color: var(--accent);
    opacity: 0;
}


.checkmark-btn input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0
}


.checkmark-btn input:checked~div:after,
.checkmark-btn input:checked~span:after  {

    opacity: 1;
}

.form-control:focus-visible,

.radio-btn input:focus-visible ~ span,
.checkbox-btn input:focus-visible ~ span,
.checkmark-btn input:focus-visible ~ span,
.switch input:focus-visible ~ span,
.switch input:focus-visible ~ span .switch__btn {
        outline: -webkit-focus-ring-color auto 1px;
}


















.thumbnail {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.thumbnail img {
    border: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnail iframe {
    border: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.thumbnail-wide {
    padding-bottom: 56.25%
}

.thumbnail-square {
    padding-bottom: 100%
}

.thumbnail-landscape {
    padding-bottom: 75%
}

.thumbnail-half {
    padding-bottom: 50%
}

.thumbnail-third {
    padding-bottom: 33.33333%
}

.thumbnail-quarter {
    padding-bottom: 25%
}

.bg-cover {
    background-size: cover;

    img {
        object-fit: cover;
    }
}

.bg-contain {
    background-size: contain;

    img {
        object-fit: contain;
    }
}

.avatar {
    position: relative;
    width: 48px;
    height: 48px;
    text-align: center;
    border-radius: 50%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.avatar-img {

    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;

    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img,
    svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
    }
}

.avatar-large {
    width: 64px;
    height: 64px;
    line-height: 64px;

}

.avatar-icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    background: white;
    padding: 4px;
    z-index: 4;
}

.avatar-small {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.avatar-tiny {
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.icon {
    width: 24px;
    height: 24px;
    position: relative;

    img,
    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: currentColor;
    }


}

.icon-small {
    width: 18px;
    height: 18px;
}

.icon-badge {
    padding: 0 4px;
    background: var(--red);
    border-radius: 10rem;
    top: 0;
    right: 0;
    position: absolute;
    font-size: 9px;
    line-height: 1;
    color: white;
    font-weight: 700;
    line-height: 18px;
    min-width: 18px;
    text-align: center;
}


.icon-dot:after {
    content: "";
    width: 8px;
    height: 8px;
    background-color: var(--red);
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
}



.card {
    background-color: var(--card-bg);
    position: relative;
}

a {
    color: var(--accent);
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: underline
}

b,
strong {
    font-weight: 600
}

pre,
code {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

img {
    max-width: 100%;
    border-style: none
}

article {
    p {
        margin: 0 auto 2.2rem
    }

    p img {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto
    }

    ol,
    ul {
        margin: 0 auto 2.2rem;
        padding-left: 2.4rem
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin: 0
    }

    ol ol {
        list-style: lower-alpha
    }

    blockquote {
        padding-left: 2.4rem;
        position: relative;
        border: 0 !important;
        margin: 0 auto 2.2rem
    }

    blockquote p:last-of-type {
        margin: 0;
    }

    blockquote:before {
        content: "";
        position: absolute;
        top: 4px;
        bottom: 4px;
        width: 8px;
        left: 0;
        background: var(--sky)
    }

    figure {
        margin: 0 auto 2.2rem
    }

    figure img {
        display: block
    }

    figcaption {
        color: var(--gray);
        margin-top: 8px;
        font-size: 1.4rem;
        line-height: 1.8rem
    }
}



.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.separator {
    clear: both;
    height: 0;
    border-bottom: solid 1px var(--border-color);
}

.bordered {
    border: solid 1px #d8d9da;
}

.rounded {
    border-radius: var(--rounded);
}

.rounded-small {
    border-radius: var(--rounded-small);
}

.depth-1 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .12) inset;
}

.depth-4 {
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)
}

.depth-8 {
    box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)
}

.depth-16 {
    box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)
}

.depth-64 {
    box-shadow: 0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)
}




.p-0 {
    padding: 0
}

.p-4 {
    padding: 4px
}

.p-8 {
    padding: 8px;
}

.p-12 {
    padding: 12px;
}

.p-16 {
    padding: 16px;
}

.p-20 {
    padding: 20px;
}

.p-24 {
    padding: 24px;
}

.pl-0 {
    padding-left: 0
}

.pl-4 {
    padding-left: 4px
}

.pl-8 {
    padding-left: 8px;
}

.pl-12 {
    padding-left: 12px;
}

.pl-16 {
    padding-left: 16px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-24 {
    padding-left: 24px;
}

.pt-0 {
    padding-top: 0
}

.pt-4 {
    padding-top: 4px
}

.pt-8 {
    padding-top: 8px;
}

.pt-12 {
    padding-top: 12px;
}

.pt-16 {
    padding-top: 16px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-24 {
    padding-top: 24px;
}

.pr-0 {
    padding-right: 0
}

.pr-4 {
    padding-right: 4px
}

.pr-8 {
    padding-right: 8px;
}

.pr-12 {
    padding-right: 12px;
}

.pr-16 {
    padding-right: 16px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-24 {
    padding-right: 24px;
}

.pb-0 {
    padding-bottom: 0
}

.pb-4 {
    padding-bottom: 4px
}

.pb-8 {
    padding-bottom: 8px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-24 {
    padding-bottom: 24px;
}


.m-a {
    margin: auto;
}

.ml-a {
    margin-left: auto;
}

.mr-a {
    margin-right: auto;
}

.m-0 {
    margin: 0
}

.m-4 {
    margin: 4px
}

.m-8 {
    margin: 8px;
}

.m-12 {
    margin: 12px;
}

.m-16 {
    margin: 16px;
}

.m-20 {
    margin: 20px;
}

.m-24 {
    margin: 24px;
}

.ml-0 {
    margin-left: 0
}

.ml-4 {
    margin-left: 4px
}

.ml-8 {
    margin-left: 8px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-16 {
    margin-left: 16px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-24 {
    margin-left: 24px;
}

.mt-0 {
    margin-top: 0
}

.mt-4 {
    margin-top: 4px
}

.mt-8 {
    margin-top: 8px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mr-0 {
    margin-right: 0
}

.mr-4 {
    margin-right: 4px
}

.mr-8 {
    margin-right: 8px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-24 {
    margin-right: 24px;
}

.mb-0 {
    margin-bottom: 0
}

.mb-4 {
    margin-bottom: 4px
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {

    margin-bottom: 24px;
}












.badge {
    display: inline-flex;
    line-height: 22px;
    border: solid 1px rgba(0, 0, 0, 0);
    padding: 0 8px;
    border-radius: 10em;
    background: var(--ink);
    font-size: 12px;
    align-items: center;
    color: white;
    font-weight: 600;

    &.badge-line {
        background: white;
        color: var(--ink);
        border-color: var(--ink);
    }

    &-info {
        background: var(--blue);

        &.badge-line {
            color: var(--blue);
            border-color: var(--blue);
        }
    }

    &-success {
        background: var(--green);

        &.badge-line {
            color: var(--green);
            border-color: var(--green);
        }
    }

    &-warning {
        background: var(--orange);

        &.badge-line {
            color: var(--orange);
            border-color: var(--orange);
        }
    }

    &-danger {
        background: var(--red);

        &.badge-line {
            color: var(--red);
            border-color: var(--red);
        }
    }


    img,
    svg {
        display: block;
        width: 16px;
        height: 16px;
        margin: auto 4px auto 0;
    }

}






.alert {
    display: flex;
    border: solid 1px rgba(0, 0, 0, 0);
    padding: 12px 16px;
    border-radius: var(--card-radius);
    background: #f3f4f6;
    align-items: center;
    color: var(--ink);
    border: solid 1px var(--border-color);


    &-info {
        background: #dbeafe;
        border-color:#bfdbfe;
    }

    &-success {
        background: #dcfce7;
        border-color:#bbf7d0;
    }

    &-warning {
        background: #ffedd5;
        border-color:#fed7aa;
    }

    &-danger {
        background: #fee2e2;
        border-color:#fecaca;
    }


}



.snackbar {
    display: flex;
    padding: 12px 16px;
    border-radius: var(--card-radius);
    align-items: center;
    background-color: var(--ink);
    color: white;

}




span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: var(--blue);
    opacity: .12;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.btn-ripple {
    position: relative;
    overflow: hidden;
}





.tab {
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--border-color);

    a {
        display: flex;
        line-height: 24px;
        text-decoration: none;
        color: inherit;
        padding: 12px 16px;
        text-align: center;
        justify-content: center;

        position: relative;

        &.active {

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: var(--accent);
            }
        }
    }
}



.panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    transform: translateY(120%);
    transition: .2s;
}

.panel.active {
    transform: translate(0);
}

.panel>.rounded {
    border-radius: 16px 16px 0 0;
}